<template>
  <div class="container" v-if="!!userId">
    <div class="row">
      <div class="col-sm-12">
        <p class="text-left" v-if="!!userName">
          <span>الشركات التي قامت بحظر المستخدم</span>&nbsp;<span
            class="link"
            >{{ userName }}</span
          >
        </p>
        <div class="w-100 text-right">
          <modal-btn target="#add-block" btn-class="btn-sm btn-soft-danger"
            >إضافة</modal-btn
          >
        </div>
        <div class="table-responsive">
          <table class="table table-bordered text-center">
            <tr class="bg-bb">
              <th>الشركة</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="!hasBlocks" class="bg-light text-dark">
              <td colspan="2">لم يتم حظر المستخدم</td>
            </tr>
            <tr
              v-else
              v-for="block in blocks"
              :key="block.id"
              :id="`block-row${block.id}`"
              class="bg-light text-dark"
            >
              <td>{{ block.company }}</td>
              <td>
                <modal-btn
                  :target="`#delete-block${block.id}`"
                  btn-class="btn pm-0"
                  title="إزالة حظر"
                >
                  <i class="text-danger fa fa-trash-alt"></i>
                </modal-btn>
                <items-destroyer
                  title="إزالة حظر"
                  :target-id="`delete-block${block.id}`"
                  :row-id="`block-row${block.id}`"
                  :url="`user-blocks/${block.id}`"
                  @deleted="deleted"
                >
                  <span>هل أنت متأكد من إزالة الحظر من الشركة</span>&nbsp;<span
                    class="link"
                    >{{ block.company }}</span
                  >&nbsp;؟
                </items-destroyer>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        target-id="add-block"
        title="حظر المستخدم"
        @confirmed="clickById('add-block-submit')"
      >
        <form @submit.prevent="store">
          <p>يرجى تحديد الشركة</p>
          <bootstrap-select
            id="company"
            :options="companies"
            :empty-option="false"
            :search="false"
          ></bootstrap-select>
          <button hidden id="add-block-submit" type="submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import Storage from "@/common/Storage";
import ModalBtn from "@/components/UI/ModalBtn";
import companies from "@/mixins/companies";
import ConfirmModel from "@/components/UI/ConfirmModel";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import arrays from "@/common/arrays";
import types from "@/common/types";

export default {
  components: { ItemsDestroyer, BootstrapSelect, ConfirmModel, ModalBtn },
  mixins: [companies],
  data() {
    return {
      blocks: [],
      userName: "",
    };
  },
  computed: {
    userId() {
      const route = this.$route;
      return !!route ? route.params.user : "";
    },
    hasBlocks() {
      return !(!this.blocks || this.blocks.length === 0);
    },
    storageName() {
      return `user-blocks-${this.userId}`;
    },
  },
  methods: {
    async setUserBlocks() {
      const storage = Storage.get(this.storageName);
      if (!!storage) return this.setData(storage.data);
      const response = await http.send("user-blocks", {
        _method: "GET",
        user: this.userId,
      });
      if (http.responseAccepted(response)) this.setData(response.data);
    },
    async store() {
      const company = $_("#company").val();
      if (!company) return http.popupMessage("error", "يرجى تحديد الشركة");
      if (this.checkBlocked(company))
        return http.popupMessage(
          "error",
          "تم حظر المستخدم من هذه الشركة مسبقاً"
        );
      const response = await http.send("user-blocks", {
        user: this.userId,
        company,
      });
      if (http.responseAccepted(response)) {
        this.setData(response.data);
        this.hideModal("#add-block");
        $_("#company").selectpicker("val", "");
      }
    },
    deleted(id) {
      if (!id) return;
      this.setData({
        blocks: arrays.removeById(this.blocks, id),
        userName: this.userName,
      });
    },
    checkBlocked(company) {
      if (!company || !this.hasBlocks || this.blocks.length === 0) return false;
      return this.blocks.find(
        (block) => parseInt(block.company_id) === parseInt(company)
      );
    },
    setData(data) {
      Storage.removeStorageKeys("user-blocks-");
      if (!data) return;
      if (!!data.blocks) this.blocks = types.objectToArray(data.blocks);
      if (!!data.userName) this.userName = data.userName;
      Storage.set(this.storageName, data, 10);
    },
  },
  async created() {
    await this.setUserBlocks();
  },
};
</script>
